import axios from "axios";

 const BaseUrl = "https://devapi.fleatiger.com/"

export const executePost = async (endpoint,data)=>{  
    return await axios.post(BaseUrl + endpoint,data);
}

export const executeGET = async (endPoint) => {
    return await axios.get(BaseUrl + endPoint);
  };